<template>
  <b-container class="container-fluid max-width">
    <h1 data-cy="title" class="text-center">
      Add a Company
    </h1>
    <b-form-group label="Company Name">
      <b-form-input v-model="companyName" />
    </b-form-group>
    <b-form-group label="Entity Type">
      <b-form-select v-model="entityTypeId" :options="entitySelectionValues" />
    </b-form-group>

    <b-button
      variant="primary"
      class="mt-4 mx-auto"
      style="width:200px;"
      aria-label="next button"
      :disabled="!valid"
      @click="createCompany"
    >
      Next
    </b-button>
  </b-container>
</template>

<script>
import { isNotEmptyOrNull } from '@/common/modules/strings'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddCompany',
  components: {},
  data() {
    return {
      loaded: false,
      company: null,
      companyName: null,
      entityTypeId: null,
      suffix: null,
    }
  },
  computed: {
    ...mapGetters('entityTypes', ['entitySelectionValues']),
    valid() {
      const companyNameIsValid = isNotEmptyOrNull(this.companyName)
      const entityTypeIdIsValid = isNotEmptyOrNull(this.entityTypeId)
      return companyNameIsValid && entityTypeIdIsValid
    },
  },
  async mounted() {
    await this.getEntityTypes()
    this.loaded = true
  },
  methods: {
    ...mapActions('entityTypes', ['getEntityTypes']),
    createCompany() {
      this.$emit('create', this.companyName, this.entityTypeId)
    },
  },
}
</script>

<style lang="scss" scoped></style>
