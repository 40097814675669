var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "container-fluid max-width" },
    [
      _c("h1", { staticClass: "text-center", attrs: { "data-cy": "title" } }, [
        _vm._v("\n    Add a Company\n  "),
      ]),
      _c(
        "b-form-group",
        { attrs: { label: "Company Name" } },
        [
          _c("b-form-input", {
            model: {
              value: _vm.companyName,
              callback: function ($$v) {
                _vm.companyName = $$v
              },
              expression: "companyName",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Entity Type" } },
        [
          _c("b-form-select", {
            attrs: { options: _vm.entitySelectionValues },
            model: {
              value: _vm.entityTypeId,
              callback: function ($$v) {
                _vm.entityTypeId = $$v
              },
              expression: "entityTypeId",
            },
          }),
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "mt-4 mx-auto",
          staticStyle: { width: "200px" },
          attrs: {
            variant: "primary",
            "aria-label": "next button",
            disabled: !_vm.valid,
          },
          on: { click: _vm.createCompany },
        },
        [_vm._v("\n    Next\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }